export const Milestones = [
  {
    "milestoneType": "job",
    "startDate": "2019-09-01T00:00:00",
    "endDate": "",
    "title": "Product Manager",
    "company": "Aotal Ltd.",
    "location": "Auckland, New Zealand"
  },
  {
    "milestoneType": "job",
    "startDate": "2018-12-03T00:00:00",
    "endDate": "2019-08-31T00:00:00",
    "title": "Business Analyst",
    "company": "Aotal Ltd.",
    "location": "Auckland, New Zealand"
  },
  {
    "milestoneType": "travel",
    "startDate": "2018-07-10T00:00:00",
    "endDate": "2018-07-10T00:00:00",
    "title": "Moved to New Zealand",
    "company": "",
    "location": ""
  },
  {
    "milestoneType": "job",
    "startDate": "2017-10-01T00:00:00",
    "endDate": "2018-09-01T00:00:00",
    "title": "Business Analyst",
    "company": "Bookassist",
    "location": "Dublin, Ireland"
  },
  {
    "milestoneType": "job",
    "startDate": "2015-09-01T00:00:00",
    "endDate": "2017-09-30T00:00:00",
    "title": "Operations Team Lead",
    "company": "Global Payments Inc.",
    "location": "Dublin, Ireland"
  },
  {
    "milestoneType": "job",
    "startDate": "2013-11-01T00:00:00",
    "endDate": "2015-08-31T00:00:00",
    "title": "Operations Analyst",
    "company": "Global Payments Inc.",
    "location": "Dublin, Ireland"
  },
  {
    "milestoneType": "travel",
    "startDate": "2013-01-17T00:00:00",
    "endDate": "2013-01-17T00:00:00",
    "title": "Moved to Ireland",
    "company": "",
    "location": ""
  },
  {
    "milestoneType": "job",
    "startDate": "2012-10-01T00:00:00",
    "endDate": "2013-10-31T00:00:00",
    "title": "Mobile & Web Developer",
    "company": "Freelance",
    "location": "Dublin, Ireland"
  },
  {
    "milestoneType": "job",
    "startDate": "2010-03-01T00:00:00",
    "endDate": "2012-10-30T00:00:00",
    "title": "Business Analsyt",
    "company": "SIANI",
    "location": "Las Palmas de Gran Canaria, Spain"
  },
  {
    "milestoneType": "job",
    "startDate": "2008-10-01T00:00:00",
    "endDate": "2010-04-30T00:00:00",
    "title": "Software Engineer",
    "company": "SIANI",
    "location": "Las Palmas de Gran Canaria, Spain"
  }
];

export default Milestones;