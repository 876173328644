import React from 'react';
import './App.css';

import { Milestones as milestones } from './data';

const Year = (props) => {
  const yearStart = new Date(props.startDate).toLocaleString('default', { year: 'numeric' });
  var yearEnd = "Today";

  if (props.endDate !== '')
    yearEnd = new Date(props.endDate).toLocaleString('default', { year: 'numeric' });

  if (yearStart!==yearEnd) {
    return  <div className="milestone">
              <span className="milestone-label milestone-year">{yearEnd}</span>
              <span className={`circle outlined ${yearEnd.toLowerCase()}`}></span>
            </div>
  }
}

const Milestone = (props) => {
  const milestoneDate = new Date(props.startDate).toLocaleString('en-US', { month: 'short', year: '2-digit' });
  const milestoneDurationStr = getPeriodDurationString(props.startDate, props.endDate);

  return <div className="experience-item">
            { <Year startDate={props.startDate} endDate={props.endDate}/> }
            <div className={`line ${props.lineStyle}`}></div>
            <div className="milestone">
              <div className="milestone-label milestone-month">{ milestoneDate }</div>
              <span className={`circle ${props.circleStyle} ${props.type}`}></span>
              <div className="milestone-text">
                <div className='milestone-title'>{props.role}</div>                
              </div>      
            </div>
            <div className={`line ${props.lineStyle} ${props.type}`}>
              <div className="milestone-duration">{milestoneDurationStr}</div>
              <div className="company-name">{props.company}</div>
              <div className="company-location">{props.location}</div>
            </div>
          </div>
}
  

Milestone.defaultProps = {
  circleStyle: "filled",
  lineStyle: "solid"
};

function getPeriodDurationString (startDateStr, endDateStr) {
  const startDate = new Date(startDateStr);

  if (isNaN(startDate)) return "";

  if (endDateStr === '')
    var endDate = new Date();
  else
    var endDate = new Date(endDateStr);

  if (isNaN(endDate)) return "";

  const yearsDiff = endDate.getFullYear() - startDate.getFullYear();
  const monthsDiff = endDate.getMonth() - startDate.getMonth();
  const totalMonths = yearsDiff * 12 + monthsDiff;

  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  // Remove 'years' or 'months' wording when value is 0,
  // also removing the 's' for yearS and monthS when it has to be singular (value = 1)
  if (years === 0 && months === 0) return "";
  else if (years === 0) return `${months} month` + ((months > 1) ? "s" : "")
  else if (months === 0) return `${years} year` + ((years > 1) ? "s" : "")
  else return `${years} year` + ((years > 1) ? "s" : "") + ` and ${months} month` + ((months > 1) ? "s" : "")
}

function Milestones () {
  return milestones.map( 
    milestone => 
      <Milestone 
        key={`${milestone.startDate}`}
        type={`${milestone.milestoneType}`}
        endDate={`${milestone.endDate}`}
        startDate={`${milestone.startDate}`}
        role={`${milestone.title}`}
        company={`${milestone.company}`}
        location={`${milestone.location}`} />
  );
};

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="personal-details">
          <div className="personal-details-header">
            <div className="title">Eliel <span className="phonetical">/Eh-Lee-El/</span></div>
            <div className="last-name">Cruz L&oacute;pez</div>
            <div className="subtitle">Product Manager</div>
          </div>
          <div className="contact-details">
            <div className="contact-details-item">
              <img alt="" className="icon" src="images/map-pin.svg"/>
              <span className="contact-details-item-text">Auckland</span>
            </div>
            <div className="contact-details-item">
              <img alt="" className="icon" src="images/smartphone.svg"/>
              <span className="contact-details-item-text">+64 (0) 21 034 1793</span>
            </div>
            <div className="contact-details-item">
              <img alt="" className="icon" src="images/mail.svg"/>
              <span className="contact-details-item-text">elielcruz@gmail.com</span>
            </div>
            <div className="contact-details-item">
              <img alt="" className="icon" src="images/linkedin.svg"/>
              <a href="https://linkedin.com/in/elielcruzlopez" target="_blank" rel="noreferrer" className="contact-details-item-text">linkedin.com/in/elielcruzlopez</a>
            </div>
          </div>
        </div>
        <div className="container-main-content">
          <h1>Bio</h1>
          <div className="bio">
            <p className="first-paragraph">I started my professional career as a Software Engineer,
            a path that was regarded as the logical choice for many with a <strong>Computer Science 
              background</strong>. While I found the problem-solving aspect of Software Development 
              exhilarating, I soon realised that I wanted to be <strong>more closely involved with clients
                and end users</strong>.</p>
            <p>With a solid <strong>technical background and extensive international experience</strong>, I
             have evolved into a hands-on Product Manager who has integrated the Engineering and Operations 
             point of view into the craft of Product Management. Moreover, my passion for UX Design further 
             enriches my ability to tackle customer problems from multiple angles.</p>
          
          </div>
          <h1>Experience</h1>
          <div className="timeline-container">
            <div className="timeline">
              <Milestones />
              <div className="experience-item">
                <div className="milestone">
                  <span className="milestone-label milestone-year">2008</span>
                  <span className="circle outlined"></span>
                  <div className="milestone-text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
